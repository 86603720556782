import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';

export const PERMISSIONS = new InjectionToken<Permissions | undefined>('PERMISSIONS_TOKEN', {
  providedIn: 'root',
  factory: () => {
    const document = inject(DOCUMENT);
    return document.defaultView?.navigator.permissions;
  },
});
