<tui-root>
  <router-outlet />

  <ng-container ngProjectAs="tuiOverContent">
    <fin-selected-instruments-summary-popup-host />
  </ng-container>
  <ng-container ngProjectAs="tuiOverDialogs">
    <!-- Content over dialogs-->
  </ng-container>
  <ng-container ngProjectAs="tuiOverNotifications">
    <!-- Content over notifications -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverPortals">
    <!-- Content over dropdowns -->
  </ng-container>
  <ng-container ngProjectAs="tuiOverHints">
    <!-- Content over hints -->
  </ng-container>
</tui-root>
