import { Inject, Injectable, Injector } from '@angular/core';
// ɵAngularFireSchedulers is a private dependency
import { ɵAngularFireSchedulers } from '@angular/fire';
import type { MessagePayload } from '@angular/fire/messaging';
import { Messaging, deleteToken, getToken, isSupported, onMessage } from '@angular/fire/messaging';

import {
  EMPTY,
  Observable,
  Subscription,
  catchError,
  concat,
  debounceTime,
  defaultIfEmpty,
  distinctUntilChanged,
  filter,
  from,
  map,
  mergeMap,
  observeOn,
  of,
  shareReplay,
  subscribeOn,
  switchMap,
} from 'rxjs';

import { AuthenticationTokenStorageService } from '@finverity/authentication';
import { CreatePushTokenGQL, PushTokenType } from '@finverity/graphql';

import { NOTIFICATION, PERMISSIONS, SERVICE_WORKER_CONTAINER } from './web-api-tokens';

/**
 * @link https://firebase.google.com/docs/cloud-messaging/js/receive#web-version-9_3
 * @warnings https://github.com/firebase/firebase-js-sdk/issues/2364 - status open
 * */
@Injectable({
  providedIn: 'root',
})
export class FirebaseCloudMessagingService {
  readonly requestPermission: Observable<NotificationPermission>;
  readonly getToken: Observable<string | null>;
  readonly tokenChanges: Observable<string | null>;
  readonly messages: Observable<MessagePayload>;
  readonly requestToken: Observable<string | null>;
  readonly deleteToken: (token: string) => Observable<boolean>;

  private setupPushTokenSubscription: Subscription = Subscription.EMPTY;

  constructor(
    @Inject(SERVICE_WORKER_CONTAINER) private readonly serviceWorker: ServiceWorkerContainer,
    @Inject(NOTIFICATION) private readonly notification: typeof Notification,
    @Inject(PERMISSIONS) private readonly permissions: Permissions,
    private readonly injector: Injector,
    readonly schedulers: ɵAngularFireSchedulers,
    private readonly authTokenStorageService: AuthenticationTokenStorageService,
    private readonly createPushTokenGQL: CreatePushTokenGQL
  ) {
    const messaging = of(undefined).pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(() => isSupported() as Promise<boolean>),
      map(supported => (supported ? this.getMessagingInstance() : null)),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    this.requestPermission = messaging.pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(() => this.notification.requestPermission())
    );

    this.getToken = messaging.pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(messaging => {
        if (this.notification.permission === 'granted') {
          return this.registerServiceWorker().pipe(
            switchMap(serviceWorkerRegistration => getToken(messaging!, { serviceWorkerRegistration }))
          );
        }

        return of(null);
      })
    );

    const notificationPermission$ = this.createNotificationPermissionsStatusChangeObservable();

    const tokenChange$ = messaging.pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(() => notificationPermission$),
      switchMap(() => this.getToken)
    );

    this.tokenChanges = messaging.pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(() => concat(this.getToken, tokenChange$))
    );

    this.messages = messaging.pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(messaging => this.createMessagesObservable(messaging!))
    );

    this.requestToken = messaging.pipe(
      // @ts-ignore
      subscribeOn(schedulers.outsideAngular),
      // @ts-ignore
      observeOn(schedulers.insideAngular),
      switchMap(() => this.requestPermission),
      catchError(() => of(null)),
      mergeMap(() => this.tokenChanges)
    );

    this.deleteToken = () =>
      messaging.pipe(
        // @ts-ignore
        subscribeOn(schedulers.outsideAngular),
        // @ts-ignore
        observeOn(schedulers.insideAngular),
        switchMap(messaging => deleteToken(messaging!)),
        defaultIfEmpty(false)
      );
  }

  init(): void {
    this.requestPermission.subscribe();

    this.setupPushTokenSubscription = this.authTokenStorageService.accessToken$
      .pipe(
        debounceTime(0), // Wait for the token to be available from "authTokenStorageService.getToken()" for filter below
        distinctUntilChanged(),
        filter(Boolean),
        filter(() => !!this.authTokenStorageService.getToken()), // Don't include "temporal" token
        switchMap(() =>
          this.requestToken.pipe(switchMap(fcmToken => (fcmToken ? this.setupPushToken(fcmToken) : EMPTY)))
        )
      )
      .subscribe();
  }

  // Lookup Messaging instance through Injector only when isSupported() check has been done
  getMessagingInstance(): Messaging {
    return this.injector.get(Messaging);
  }

  private setupPushToken(token: string): Observable<boolean> {
    return this.createPushTokenGQL.mutate({ createPushTokenInput: { type: PushTokenType.Web, token } }).pipe(
      map(result => !!result.data?.createPushToken),
      catchError(() => EMPTY)
    );
  }

  private createNotificationPermissionsStatusChangeObservable(): Observable<PermissionState> {
    return new Observable<PermissionState>(subscriber => {
      this.permissions.query({ name: 'notifications' }).then(permissionStatus => {
        permissionStatus.onchange = event => subscriber.next((event.target as PermissionStatus).state);
      });
    });
  }

  private createMessagesObservable(messaging: Messaging): Observable<MessagePayload> {
    return new Observable<MessagePayload>(subscriber => {
      return onMessage(messaging, messagePayload => {
        subscriber.next(messagePayload);
      });
    });
  }

  // type: 'module' only supported in the browsers Chrome and Safari, not in Mozilla
  // because of that serviceWorker registration file(firebase-messaging-sw.js) using importScripts for -compat libraries
  // https://github.com/firebase/firebase-js-sdk/issues/5403
  private registerServiceWorker(): Observable<ServiceWorkerRegistration> {
    return from(
      this.serviceWorker
        .register('service-workers/firebase-messaging-sw.js', { type: 'classic' })
        .then(serviceWorkerRegistration => serviceWorkerRegistration)
        .catch(error => {
          console.warn({ serviceWorkerRegistrationFailure: error });
          throw error;
        })
    );
  }
}
