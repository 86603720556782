import { DOCUMENT } from '@angular/common';
import { InjectionToken, inject } from '@angular/core';

export const SERVICE_WORKER_CONTAINER = new InjectionToken<ServiceWorkerContainer | undefined>(
  'SERVICE_WORKER_CONTAINER_TOKEN',
  {
    providedIn: 'root',
    factory: () => {
      const document = inject(DOCUMENT);
      return document.defaultView?.navigator.serviceWorker;
    },
  }
);
