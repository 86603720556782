import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { map, shareReplay, tap } from 'rxjs/operators';

import { FinEnvironmentService } from '@finverity/platform-app/core/services';
import { isArrayFull } from '@finverity/platform-app/core/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyDocumentsService {
  private readonly userDocumentsUrl!: string;
  private readonly companyDocumentsSubject = new BehaviorSubject<CompanyDocument[]>([]);

  constructor(
    private http: HttpClient,
    private readonly environmentService: FinEnvironmentService
  ) {
    this.userDocumentsUrl = `${this.environmentService.api.userDocumentsUrl}`;
  }

  getCompanyDocuments() {
    return this.http.get<any>(this.userDocumentsUrl).pipe(
      map((documents: any) =>
        isArrayFull(documents)
          ? documents.map((doc: any) => ({
              ...doc,
              image: 'assets/images/components/shared/pdf-icon.svg',
            }))
          : []
      ),
      tap((companyDocuments: CompanyDocument[]) => {
        this.setCompanyDocuments(companyDocuments);
      }),
      shareReplay()
    );
  }

  private setCompanyDocuments(companyDocuments: CompanyDocument[]) {
    this.companyDocumentsSubject.next(companyDocuments);
  }
}

export interface CompanyDocument {
  uuid?: any;
  title: string;
  description?: string;
  type?: string;
  name: string;
  file_link: string;
  need_sign?: boolean;
  image?: string;
}
