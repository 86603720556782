import '@angular/common/locales/global/en-150';
import '@angular/common/locales/global/en-001';
import '@angular/common/locales/global/en-AE';
import '@angular/common/locales/global/en-AI';
import '@angular/common/locales/global/en-AG';
import '@angular/common/locales/global/en-AS';
import '@angular/common/locales/global/en-AT';
import '@angular/common/locales/global/en-AU';
import '@angular/common/locales/global/en-BB';
import '@angular/common/locales/global/en-BE';
import '@angular/common/locales/global/en-BI';
import '@angular/common/locales/global/en-BM';
import '@angular/common/locales/global/en-BS';
import '@angular/common/locales/global/en-BW';
import '@angular/common/locales/global/en-BZ';
import '@angular/common/locales/global/en-CA';
import '@angular/common/locales/global/en-CC';
import '@angular/common/locales/global/en-CH';
import '@angular/common/locales/global/en-CK';
import '@angular/common/locales/global/en-CM';
import '@angular/common/locales/global/en-CX';
import '@angular/common/locales/global/en-CY';
import '@angular/common/locales/global/en-DE';
import '@angular/common/locales/global/en-DG';
import '@angular/common/locales/global/en-DK';
import '@angular/common/locales/global/en-DM';
import '@angular/common/locales/global/en-ER';
import '@angular/common/locales/global/en-FI';
import '@angular/common/locales/global/en-FJ';
import '@angular/common/locales/global/en-FK';
import '@angular/common/locales/global/en-FM';
import '@angular/common/locales/global/en-GB';
import '@angular/common/locales/global/en-GD';
import '@angular/common/locales/global/en-GG';
import '@angular/common/locales/global/en-GH';
import '@angular/common/locales/global/en-GI';
import '@angular/common/locales/global/en-GM';
import '@angular/common/locales/global/en-GU';
import '@angular/common/locales/global/en-GY';
import '@angular/common/locales/global/en-HK';
import '@angular/common/locales/global/en-IE';
import '@angular/common/locales/global/en-IL';
import '@angular/common/locales/global/en-IM';
import '@angular/common/locales/global/en-IN';
import '@angular/common/locales/global/en-IO';
import '@angular/common/locales/global/en-JE';
import '@angular/common/locales/global/en-JM';
import '@angular/common/locales/global/en-KE';
import '@angular/common/locales/global/en-KI';
import '@angular/common/locales/global/en-KN';
import '@angular/common/locales/global/en-KY';
import '@angular/common/locales/global/en-LC';
import '@angular/common/locales/global/en-LR';
import '@angular/common/locales/global/en-LS';
import '@angular/common/locales/global/en-MG';
import '@angular/common/locales/global/en-MH';
import '@angular/common/locales/global/en-MO';
import '@angular/common/locales/global/en-MP';
import '@angular/common/locales/global/en-MS';
import '@angular/common/locales/global/en-MT';
import '@angular/common/locales/global/en-MU';
import '@angular/common/locales/global/en-MW';
import '@angular/common/locales/global/en-MY';
import '@angular/common/locales/global/en-NA';
import '@angular/common/locales/global/en-NF';
import '@angular/common/locales/global/en-NG';
import '@angular/common/locales/global/en-NL';
import '@angular/common/locales/global/en-NR';
import '@angular/common/locales/global/en-NU';
import '@angular/common/locales/global/en-NZ';
import '@angular/common/locales/global/en-PG';
import '@angular/common/locales/global/en-PH';
import '@angular/common/locales/global/en-PK';
import '@angular/common/locales/global/en-PN';
import '@angular/common/locales/global/en-PR';
import '@angular/common/locales/global/en-PW';
import '@angular/common/locales/global/en-RW';
import '@angular/common/locales/global/en-SB';
import '@angular/common/locales/global/en-SC';
import '@angular/common/locales/global/en-SD';
import '@angular/common/locales/global/en-SE';
import '@angular/common/locales/global/en-SG';
import '@angular/common/locales/global/en-SH';
import '@angular/common/locales/global/en-SI';
import '@angular/common/locales/global/en-SL';
import '@angular/common/locales/global/en-SS';
import '@angular/common/locales/global/en-SX';
import '@angular/common/locales/global/en-SZ';
import '@angular/common/locales/global/en-TC';
import '@angular/common/locales/global/en-TK';
import '@angular/common/locales/global/en-TO';
import '@angular/common/locales/global/en-TT';
import '@angular/common/locales/global/en-TV';
import '@angular/common/locales/global/en-TZ';
import '@angular/common/locales/global/en-UG';
import '@angular/common/locales/global/en-UM';
import '@angular/common/locales/global/en-VC';
import '@angular/common/locales/global/en-VG';
import '@angular/common/locales/global/en-VI';
import '@angular/common/locales/global/en-VU';
import '@angular/common/locales/global/en-WS';
import '@angular/common/locales/global/en-ZA';
import '@angular/common/locales/global/en-ZM';
import '@angular/common/locales/global/en-ZW';

import { bootstrapApplication } from '@angular/platform-browser';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
