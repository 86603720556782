import { APP_INITIALIZER, NgModule } from '@angular/core';

import { Observable } from 'rxjs';

import { CompanyDocumentsService } from '@finverity/platform-app/core/modules/settings/services/company-documents.service';
import { CountriesService } from '@finverity/platform-app/core/modules/settings/services/countries.service';
import { CurrenciesService } from '@finverity/platform-app/shared/services';

function initializeUserDocumentsFactory(userDocumentsService: CompanyDocumentsService): () => Observable<any> {
  return () => userDocumentsService.getCompanyDocuments();
}

function initializeCurrencyFactory(currenciesService: CurrenciesService): () => Observable<any> {
  return () => currenciesService.getCurrencies();
}

function initializeCountriesFactory(countriesService: CountriesService): () => Observable<any> {
  return () => countriesService.getCountries();
}

@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeUserDocumentsFactory,
      deps: [CompanyDocumentsService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCurrencyFactory,
      deps: [CurrenciesService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeCountriesFactory,
      deps: [CountriesService],
      multi: true,
    },
  ],
})
export class SettingsModule {}
