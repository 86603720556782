import { applyAggregateErrorsToEvent } from '@sentry/utils';
import { exceptionFromError } from '../eventbuilder.js';
const DEFAULT_KEY = 'cause';
const DEFAULT_LIMIT = 5;

/** Adds SDK info to an event. */
class LinkedErrors {
  /**
   * @inheritDoc
   */
  static __initStatic() {
    this.id = 'LinkedErrors';
  }

  /**
   * @inheritDoc
   */
  __init() {
    this.name = LinkedErrors.id;
  }

  /**
   * @inheritDoc
   */

  /**
   * @inheritDoc
   */

  /**
   * @inheritDoc
   */
  constructor(options = {}) {
    LinkedErrors.prototype.__init.call(this);
    this._key = options.key || DEFAULT_KEY;
    this._limit = options.limit || DEFAULT_LIMIT;
  }

  /**
   * @inheritDoc
   */
  setupOnce(addGlobalEventProcessor, getCurrentHub) {
    addGlobalEventProcessor((event, hint) => {
      const hub = getCurrentHub();
      const client = hub.getClient();
      const self = hub.getIntegration(LinkedErrors);
      if (!client || !self) {
        return event;
      }
      applyAggregateErrorsToEvent(exceptionFromError, client.getOptions().stackParser, self._key, self._limit, event, hint);
      return event;
    });
  }
}
LinkedErrors.__initStatic();
export { LinkedErrors };
