import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, of } from 'rxjs';

// TODO: need to import this from truly graphql types
// import {
//   Country,
//   CreateAddressGQL,
//   GetCountriesGQL,
//   GetCountriesQuery,
//   UpdateAddressGQL,
// } from '@finverity/graphql';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  private readonly countriesSubject: BehaviorSubject<any[] | null> = new BehaviorSubject<any[] | null>(null);
  readonly countries$: Observable<any[] | null> = this.countriesSubject.asObservable();
  // TODO: need to add really Country from graphql instead of any

  // constructor(
  //   private getCountriesGQL: GetCountriesGQL,
  //   private createAddressGQL: CreateAddressGQL,
  //   private updateAddressGQL: UpdateAddressGQL
  // TODO: add injectors from graphql types here
  // ) {}

  get countries(): any[] | null {
    // TODO: need to add really Country from graphql instead of any
    return [
      { alpha2Code: 'GB', name: 'United Kingdom' },
      { alpha2Code: 'GR', name: 'Greece' },
      { alpha2Code: 'AT', name: 'Austria' },
      { alpha2Code: 'BE', name: 'Belgium' },
      { alpha2Code: 'BG', name: 'Bulgaria' },
      { alpha2Code: 'HR', name: 'Croatia' },
      { alpha2Code: 'CY', name: 'Cyprus' },
      { alpha2Code: 'CZ', name: 'Czech Republic' },
      { alpha2Code: 'DK', name: 'Denmark' },
      { alpha2Code: 'EE', name: 'Estonia' },
      { alpha2Code: 'FI', name: 'Finland' },
      { alpha2Code: 'FR', name: 'France' },
      { alpha2Code: 'DE', name: 'Germany' },
      { alpha2Code: 'HU', name: 'Hungary' },
      { alpha2Code: 'IE', name: 'Ireland' },
      { alpha2Code: 'IT', name: 'Italy' },
      { alpha2Code: 'LV', name: 'Latvia' },
      { alpha2Code: 'LT', name: 'Lithuania' },
      { alpha2Code: 'LU', name: 'Luxembourg' },
    ];
    return this.countriesSubject?.value;
  }

  get countriesListWithAlpha2Code(): { value: string; label: string }[] | null {
    if (!this.countriesSubject.value) {
      return [];
    }
    return this.countriesSubject.value.map((r: any) => ({
      // TODO: need to add really Country from graphql instead of any
      value: r.alpha2Code,
      label: r.name,
    }));
  }

  getCountries() {
    return of(null);
    // return this.getCountriesGQL.watch().valueChanges.pipe(
    //   take(1),
    //   map(
    //     (result: ApolloQueryResult<GetCountriesQuery>) =>
    //       result?.data?.countries
    //   ),
    //   tap((countries) => this.setCountries(countries)),
    //   shareReplay({ refCount: true, bufferSize: 1 })
    // );
  }

  setCountries(countries: any[]) {
    // TODO: need to add really Country from graphql instead of any
    this.countriesSubject.next(countries);
  }
}
