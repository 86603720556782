import { NgClass, NgIf } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

import { TuiRootModule } from '@taiga-ui/core';

import { AuthenticationFacadeService } from '@finverity/authentication';
import { FinLayoutService } from '@finverity/platform-app/core/layout/services';
import { FinApiErrorHandler, FinEnvironmentService } from '@finverity/platform-app/core/services';
import { getNonceFromMetaTag, getTrustedScriptURL } from '@finverity/platform-app/core/utils';
import { SelectedInstrumentsSummaryPopupHostComponent } from '@finverity/platform-app/modules/instrument/features/selected-instruments-summary';

@Component({
  standalone: true,
  selector: 'fin-platform-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [NgIf, NgClass, RouterOutlet, TuiRootModule, SelectedInstrumentsSummaryPopupHostComponent],
})
export class AppComponent implements OnInit {
  private readonly titleService = inject(Title);
  private readonly environmentService = inject(FinEnvironmentService);
  private readonly authFacade = inject(AuthenticationFacadeService);
  private readonly layoutService = inject(FinLayoutService);
  private readonly destroyRef = inject(DestroyRef);

  readonly favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  constructor() {
    this.initializeErrorHandler();
  }

  ngOnInit() {
    this.setTitleAndFavicon();
    this.handleAuthLayoutBackground();
    this.initializeGoogleMaps();
  }

  private initializeErrorHandler(): void {
    // TODO: performance_issue - Stopping application to initialize faster
    FinApiErrorHandler.init();
  }

  private setTitleAndFavicon(): void {
    this.titleService.setTitle(this.environmentService.platform.title);
    if (this.favIcon) {
      this.favIcon.href = this.environmentService.platform.faviconUrl;
    }
  }

  private handleAuthLayoutBackground(): void {
    this.authFacade.loggedIn$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(loggedIn => {
      if (loggedIn) {
        this.layoutService.setAuthLayoutBackgroundColor();
      } else {
        this.layoutService.removeAuthLayoutBackgroundColor();
      }
    });
  }

  private initializeGoogleMaps(): void {
    const script = document.createElement('script');
    script.defer = true;
    script.src = getTrustedScriptURL(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAP2IlVBE8E3ItzeX6e9FkH0GH2mT9GClc&libraries=places&loading=async'
    );

    const nonce = getNonceFromMetaTag();

    if (nonce) {
      script.setAttribute('nonce', nonce);
    }

    document.body.appendChild(script);
  }
}
