import { Routes } from '@angular/router';

import { SelfOnboardingRoutes } from '../models';

export const SELF_ONBOARDING_ROUTES: Routes = [
  {
    path: SelfOnboardingRoutes.Base,
    loadChildren: () => import('../features/self-onboarding-page/shell/self-onboarding-page.routes'),
  },
];
