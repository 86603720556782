import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';

import { map, take } from 'rxjs';

import { AuthenticationFacadeService } from '@finverity/authentication';

export const CanMatchUnauthenticatedLayout: CanMatchFn = () => {
  const authFacade = inject(AuthenticationFacadeService);

  return authFacade.loggedIn$.pipe(
    take(1),
    map(loggedIn => !loggedIn)
  );
};
